html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-image: url(backgrounds/11_infinity_H.webp);
  background-size: auto 100%;
  animation: bg-animation 300s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes bg-animation {
  0% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: -1400%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, select {
  background: linear-gradient(#f1f1f1, #e2e2e2);
  border: 2px solid rgb(191, 202, 211);
  border-top-color: white;
  border-left-color: white;
  padding: 6px 4px;
  border-radius: 5px;
  font-weight: bold;
  color:rgb(26, 115, 150);
}

button:active {
  background:#ebf9fa;
  color: black;
}

button.selected {
  background:linear-gradient(rgb(156, 235, 173), rgb(78, 210, 107) 50%);
  border-top-color: #d6f8e7;
  border-left-color: #d6f8e7;
  color:white;
}