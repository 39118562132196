.value {
  font-family: 'Courier New', Courier, monospace;
  font-size: 56px;
  font-weight: bold;
  text-align: center;
  margin: 15px 0;
}

.unit {
  font-size: 32px;
  color: rgb(215, 233, 243);
}

.pane {
  color: white;
  background-color: rgba(109, 179, 212, 0.3);
  background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2) 25%),
                    linear-gradient(to bottom right, rgba(255, 255, 255, 0.4), transparent 30%);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top-color: rgba(255, 255, 255, 0.8);
  border-right-width: 0;
  border-bottom-width: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  max-width: 400px;
  margin: 15px 0;
  padding: 10px;
  text-shadow: #2e5f8b 2px 2px 3px;
}

.pane p {
  font-weight: bold;
}

.station_pane {
  display: flex;
}

.station_pane > * {
  flex: auto;
}

.interval_setting {
  display: flex;
}

.interval_setting > button {
  flex: auto;
  margin: 3px;
}

select {
  max-width: 50%;
}

select[disabled] {
  background: #c2c2c2;
}

ul.metobs {
  list-style-type: circle;
  font-weight: bold;
}

span.measured {
  font-weight: bold;
}